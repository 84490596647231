import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import ReactPlayer from 'react-player';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Painting a Picture while Tickling the Brain 🧠`}</h1>
    <p>{`Whats up, it’s me, again.`}</p>
    <p>{`Ever since starting the rolling fund, I’ve heard about 150 startup pitches in 2 months.`}</p>
    <p>{`One thing stood out - most people suck at explaining what they do.`}</p>
    <p>{`These are smart people. Hard working. Building interesting projects, night and day.`}</p>
    <p><strong parentName="p">{`Yet they can’t explain their business in 30 seconds in a way that is:`}</strong></p>
    <ul>
      <li parentName="ul">{`Clear`}</li>
      <li parentName="ul">{`Interesting.`}</li>
    </ul>
    <p><strong parentName="p">{`I call this painting a picture while tickling the brain.`}</strong></p>
    <p>{`Most people struggle to explain what their company does in simple terms.`}</p>
    <p>{`We hosted a sunday workshop and in one hour, you could make your pitch twice as good.`}</p>
    <p>{`So that you feel confident your message lands, everytime.`}</p>
    <p>{`^ that little format there, that’s the type of thing I taught at this weekend’s workshop. It was a blast. We had about 100 people show up on a sunday afternoon to sharpen up their skills.`}</p>
    <p><strong parentName="p">{`We covered two types of stories:`}</strong></p>
    <ol>
      <li parentName="ol">{`An informational story (a simple framework to pitch your business)`}</li>
      <li parentName="ol">{`A personal story (how to be a charming mother effer and tell great stories)`}</li>
    </ol>
    <p><strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://vimeo.com/472415689/14e0c278ea"
          }}>{`Here’s a link to the recording (90 mins)`}</a>{`, and a TLDW summary below:`}</em></strong></p>
    <ReactPlayer url='https://vimeo.com/472415689/14e0c278ea' mdxType="ReactPlayer" />
    <br />
    <p><strong parentName="p">{`Informational Stories:`}</strong></p>
    <p>{`Informational stories are all about getting your point across in the simplest way, while tickling the brain with some emotions and feelings.`}</p>
    <p><strong parentName="p">{`We used the Storybrand method`}</strong>{` for explaining your business in simple terms:`}</p>
    <ul>
      <li parentName="ul">{`Most people have `}<strong parentName="li">{`this problem`}</strong></li>
      <li parentName="ul">{`We offer `}<strong parentName="li">{`a simple solution`}</strong></li>
      <li parentName="ul">{`So that you get a `}<strong parentName="li">{`this happy ending`}</strong></li>
    </ul>
    <p>{`Participants could jump in, pitch their business, and then we would workshop it live together.`}</p>
    <p><strong parentName="p">{`For example: `}<a parentName="strong" {...{
          "href": "https://twitter.com/mbenchi10"
        }}>{`Menajem`}</a>{`, an all access pass member was first up.`}</strong></p>
    <p><strong parentName="p">{`BEFORE:`}</strong><br parentName="p"></br>{`
`}{`We re-locate manufacturing operations from china to mexico. So many benefits - Mexico can do it better and cheaper than china.`}</p>
    <p>{`^ not bad, the audience rated it a 5.5 out of 10. This is about average.`}</p>
    <p><strong parentName="p">{`AFTER:`}</strong><br parentName="p"></br>{`
`}{`Most brands are having manufacturing problems in China right now due to the Coronavirus.`}</p>
    <p>{`We, at GetAyuda, find you a factory in Mexico who can match the quality and cost of China.`}</p>
    <p>{`So you can stay focused on selling, while we’re working 24/7 to find you a backup supplier in Mexico.`}</p>
    <p><strong parentName="p">{`Another brave volunteer, Anuj:`}</strong></p>
    <p><strong parentName="p">{`BEFORE:`}</strong><br parentName="p"></br>{`
`}{`Sophya provides virtual spaces to become the future of work. Because we’re all losing our sense of community and connectedness. So anyone, wherever you work, can get together and re-discover the way we used to work.`}</p>
    <p>{`^ lots of jargon. Virtual spaces? Future of work? Rediscover the way we used to work? Huh? Not clear.`}</p>
    <p><strong parentName="p">{`AFTER:`}</strong><br parentName="p"></br>{`
`}{`Most remote workers miss bumping into their coworkers every day in the snack room or hallways.`}</p>
    <p>{`We create a virtual office, where you can walk around and bump into your coworkers casually, almost like a video game.`}</p>
    <p>{`So that people can have fun talking to their coworkers again, not just scheduled zoom meetings.`}</p>
    <p>{`^Not perfect, but much better than jargonpalooza he had 5 minutes earlier.`}</p>
    <p>{`If you wanna get better, I recommend trying out the `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=HFergI0UOAs&feature=emb_logo"
      }}><strong parentName="a">{`storybrand 1-liner exercise`}</strong></a>{`.`}</p>
    <p>{`We also covered how to take that one liner, and expand it, so you’re ready to go into as much depth as the listener is ready for. I call it having the tweet version, blog version, book version, podcast version etc.. of your story, ready at your fingertips.`}</p>
    <p>{`I’m also creating a #storytelling channel on slack for people who wanna keep practicing their skills.`}</p>
    <p><strong parentName="p">{`Entertaining Stories:`}</strong></p>
    <p>{`Getting people to “understand your company” is one thing. Getting them to “love you” is another.`}</p>
    <p>{`I’ve personally never found a better way to get people to love you than to tell personal stories.`}</p>
    <p>{`Nobody wants to hear you call yourself hardworking. That’s obnoxious and forgettable. But how many times have we heard the story of Michael Jordan being cut from his high school basketball team, and then working all summer to improve.`}</p>
    <p>{`Stories entertain. They teach. They inspire. Stories are the magic pixie dust for any leader. IF you learn to use it.`}</p>
    <p>{`You’ve probably heard me recommend Storyworthy before - it’s a great book, but tbh a little hard to put into practice and figure out what to do with.`}</p>
    <p>{`So, I remixed a few of the storyworthy principles to make them easier to put into practice`}</p>
    <p>{`The main things to get right in an entertaining story are:`}</p>
    <p><strong parentName="p">{`Highlight a specific moment of change`}</strong>{` - if there’s no change, there’s no story. So figure out what changed. Best case, it’s when YOU changed. For those at the workshop, they got to hear a story about the time I almost burned down my house (which is really a story about me being a shy kid and learning I need to be brave like the guys who saved my house from burning down)`}</p>
    <p><strong parentName="p">{`Stakes`}</strong>{` - every story needs stakes. I should be able to pause the story at any time and the listener should know :`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`What does the hero want right now? (goal)`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`What stands in their way? (obstacle)`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`What happens if they don't get it? (risk/stakes)`}</strong></li>
    </ul>
    <p>{`In my story the stakes were obvious. If nobody got brave, my house would burn down.`}</p>
    <p><strong parentName="p">{`The Oceans 11 Tactic`}</strong>{` - in every movie of the ocean’s series - they have a moment where they explain the plan (“here’s how we’re going to rob the casino”) before they do it. This is a great tactic, because then when you show the plan in action, people know where it’s deviating from the plan (shit going wrong == interesting).`}</p>
    <p><strong parentName="p">{`BUT & Therefore`}</strong>{` - Boring stories use the word “and” to connect sentences. Think of a 9 year old kid explaining what happened at the dentist.`}</p>
    <p>{`This happened and then this happened and then this happened and then this happened = boring.`}</p>
    <p>{`Great stories use “But” and “therefore” to create interesting stories. We tried to do this BUT then this happened THEREFORE we did this other thing BUT that didn’t work either SO we tried this instead BUT we forgot etc… = interesting.`}</p>
    <p>{`Just watch the way Trey Parker and Matt Stone `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?time_continue=11&v=vGUNqq3jVLg&feature=emb_logo"
      }}><strong parentName="a">{`talk about writing for South Park`}</strong></a>{` - this is the way that the best think about how they tell stories. We finished up the workshop showing these tactics in action on a personal story.`}</p>
    <p><strong parentName="p">{`A few things to do now:`}</strong>{`
If you wanna continue leveling up, here are some of my favorite resources and things from earlier in the AAP:`}</p>
    <ul>
      <li parentName="ul">{`Here’s the `}<a parentName="li" {...{
          "href": "https://app.pitch.com/app/public/presentation/50c07c40-e036-4819-8651-697a73d02cc3"
        }}><strong parentName="a">{`link to the presentation deck`}</strong></a></li>
      <li parentName="ul">{`Watch this `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=HFergI0UOAs&feature=emb_logo"
        }}><strong parentName="a">{`Storybrand video`}</strong></a></li>
      <li parentName="ul">{`Read Storyworthy ​`}<a parentName="li" {...{
          "href": "https://matthewdicks.com/storyworthy/"
        }}><strong parentName="a">{`Storyworthy`}</strong></a></li>
      <li parentName="ul">{`Read Pixar’s `}<a parentName="li" {...{
          "href": "https://www.aerogrammestudio.com/2013/03/07/pixars-22-rules-of-storytelling/"
        }}><strong parentName="a">{`22 rules of storytelling`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://vimeo.com/472415689/14e0c278ea"
        }}><strong parentName="a">{`Watch the Workshop`}</strong></a></li>
    </ul>
    <p>{`until tomorrow,`}<br parentName="p"></br>{`
`}{`shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://app.pitch.com/app/public/presentation/50c07c40-e036-4819-8651-697a73d02cc3',
        label: 'Storytelling Workshop Deck'
      }, {
        url: 'https://www.youtube.com/watch?v=HFergI0UOAs&feature=emb_logo',
        label: 'Storybrand 1-Liner Exercise'
      }, {
        url: 'https://matthewdicks.com/storyworthy/',
        label: 'Storyworthy Book'
      }, {
        url: 'https://www.aerogrammestudio.com/2013/03/07/pixars-22-rules-of-storytelling/',
        label: "Pixar's 22 Rules of Storytelling"
      }]
    }, {
      title: LinkLabel.Multimedia,
      links: [{
        url: 'https://vimeo.com/472415689/14e0c278ea',
        label: 'Storytelling Workshop Recording'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://twitter.com/mbenchi10',
        label: "AAP Member Menajem"
      }, {
        url: 'https://www.youtube.com/watch?v=vGUNqq3jVLg&feature=emb_logo',
        label: 'Storytelling advice from South Park writers Trey Park and Matt Stone'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.debating_isn_t_just_for_presidents.label,
      slug: slugify(postMap.debating_isn_t_just_for_presidents.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      